/* REACT MODULES */
import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { navigate } from "@reach/router"

/* MATERIAL UI */
import { Grid, Typography } from "@material-ui/core"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { css } from "@emotion/core"

/* LOCAL FILES */
import Background from "../images/loginBck.png"
import Progres from "../components/Progress"
import Modal from "../components/Modal"
import UseApi from "../hooks/useApi"
import { SEND_LOGIN } from "../constanst/endpoints"
import { isLoggedIn, setUser } from "../utils/auth"

const useStyles = makeStyles(theme => ({
  input: { paddingTop: 10, fontSize: "20px" },
  label: {
    color: "#1B5196",
    fontSize: "20px",
  },
  cardText: {
    fontSize: "40px",
  },
  spacing: { marginTop: "20px" },
}))

const StyledButton = withStyles({
  root: {
    background: "#1B5196",
    borderRadius: "27px",
    border: 0,
    color: "white",
    height: 48,
    width: "182px",
    padding: "0 30px",
    boxShadow: "0px 3px 6px #0000006A",
  },
  label: {
    textTransform: "capitalize",
    fontFamily: "Helvetica",
    font: "Regular 20px/24px Helvetica Neue",
  },
})(Button)

export default function Login() {
  if (isLoggedIn()) navigate("/app/home")
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "LogoEnlacce.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 3080, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const classes = useStyles()
  const [userName, setUserName] = useState("")
  const [userPass, setPassword] = useState("")
  const { response, errorApi, post, resetVal } = UseApi()
  const [showProgress, setShowProgress] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [MensajeModal, setMensajeModal] = useState("")

  useEffect(() => {
    setShowProgress(false)
    const { payload } = response
    if (payload) {
      const { user = {} } = payload
      if (Object.keys(user).length !== 0) {
        const userInfo = {
          ...user,
          username: user.User_Name,
        }
        setUser(userInfo)
        navigate("/app/home")
      }
    }
  }, [response])

  useEffect(() => {
    setShowProgress(false)
    if (errorApi) {
      setMensajeModal(errorApi)
      setshowModal(true)
    }
  }, [errorApi])

  const onClickFunction = async () => {
    try {
      await setShowProgress(true)
      const headers = {
        "Content-type": "application/json; charset=UTF-8",
      }
      const query = {
        endpoint: SEND_LOGIN,
        headers,
        data: {
          userName,
          userPass,
        },
      }
      await post(query)
    } catch (error) {
      console.log("error...: ", error)
    }
  }

  const validateButton =
    userName.length > 3 && userPass.length > 3 ? false : true

  return (
    <>
      <Progres show={showProgress} />
      {showModal ? (
        <Modal noButton>
          <Typography className={classes.cardText}>{MensajeModal}</Typography>
          <StyledButton
            onClick={() => {
              setshowModal(false)
              resetVal()
            }}
          >
            Cerrar
          </StyledButton>
        </Modal>
      ) : (
        false
      )}
      <Grid
        container
        css={css`
          width: 100%;
          height: 900px;
          min-height: 900px;
          @media (max-width: 768px) {
            height: auto;
          }
        `}
      >
        <Grid
          container
          xs={8}
          css={css`
            height: 100%;
            background-image: url(${Background});
            background-repeat: no-repeat;
            background-size: 100% 100%;
            background-position: 0% 0%;
            box-shadow: inset -20px 0px 20px -20px #1b5196;
            @media (max-width: 768px) {
            }
          `}
        >
          <a href="/">
            <img
              css={css`
                width: 282px;
                height: 73px;
                margin: 30px;
              `}
              src={logo.sharp.fluid.src}
              alt="Logo Enlacee"
            />
          </a>
        </Grid>
        <Grid
          container
          justify={"center"}
          xs={4}
          css={css`
            height: 100%;
            @media (max-width: 768px) {
            }
          `}
        >
          <Grid
            container
            align={"center"}
            justify={"center"}
            direction={"column"}
            xs={10}
            css={css`
              height: 60%;
              margin-top: 20%;
              @media (max-width: 768px) {
              }
            `}
          >
            <Typography
              css={css`
                color: #1b5196;
                font-size: 45px !important;
                font-weight: 700 !important;
                letter-spacing: 6.62px !important;
                margin-bottom: 60px;
              `}
            >
              LOGIN
            </Typography>
            <TextField
              fullWidth
              id="user"
              label="Usuario:"
              value={userName}
              onChange={e => setUserName(e.target.value)}
              className={classes.spacing}
              InputLabelProps={{
                className: classes.label,
              }}
              InputProps={{ className: classes.input }}
            />
            <TextField
              fullWidth
              id="password"
              label="Contraseña:"
              type="password"
              value={userPass}
              onChange={e => setPassword(e.target.value)}
              className={classes.spacing}
              InputLabelProps={{
                className: classes.label,
              }}
              InputProps={{ className: classes.input }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={onClickFunction}
              disabled={validateButton}
              css={css`
                border-radius: 50px !important;
                margin-top: 80px !important;
                width: 182px;
                height: 45px;
                font-size: 20px !important;
                color: #fff !important;
                @media (max-width: 768px) {
                }
              `}
            >
              ENVIAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}
